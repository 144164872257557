// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
$fa-font-path: "../webfonts";

// Bootstrap
// @import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
// @import '~bootstrap/scss/bootstrap';

@import "vue-select/dist/vue-select.css";

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";


legend {
	font-size: 1.5em;
	padding: .2em;
	margin: .5em 0;
	text-shadow: 0 0 5px rgba(0, 0, 0, .5);
	border-bottom: 1px solid #e5e5e5;
}

a {
	cursor: pointer;
}

.nowrap {
	white-space: nowrap;
}

.btn-th {
	width: 60px;
	text-align: center;
}

.hover:hover {
	cursor: pointer;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	.head-hover{
		background-color: #343a40 !important;
	}
}

.deleted {
	td,a,.card {
		color: #ccc;
	}
}

.sticky {
	position: sticky;
	z-index: 99;
	top: 5em;
	right: 5em;
	background-color: rgba(255, 255, 255, 0.5);
	box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
	.pagination {
		margin: 0;
		opacity: .9;
		z-index: 99;
	}
}